import React, { useEffect, useState } from "react";
// react plugin for creating charts

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
// import { fakeData } from "components/BubbleChart/Data";

import BubbleRadar, { RiskType } from "components/d3-bubble-radar";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from "@material-ui/core";

import { withStyles } from "@material-ui/styles";
import { RiskType as RiskData } from "util/RiskData";
import { useAuth } from "util/use-auth";
import { Roles, Risks } from "../../models";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { Cookies } from "react-cookie";
import { useRecoilState, useRecoilValue } from "recoil";
import { subCategoryState } from "../../store";

const customStyles = {
  ...styles,
  main: {
    background: "#292929",
    width: "100%",
    height: 700,
  },
  title: {
    color: "white",
    fontWeight: 600,
  },
  formControl: {
    margin: 1,
  },
  legend: {
    color: "#fff !important",
  },
};

const GreenCheckbox = withStyles({
  root: {
    color: "#6bc3ce",
    "&$checked": {
      color: "#6bc3ce",
    },
    padding: "2px 9px",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(customStyles);

export default function RadarEntries() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [initData, setInitData] = useState([]);

  const [type, setType] = useState({
    Technology: true,
    Process: true,
    Data: true,
    Asset: true,
  });

  const { authUser } = useAuth();

  const [subCategory, setSubCategory] = useRecoilState(subCategoryState);
  const [subCategoryStatus, setSubCategoryStatus] = useState({});
  const companyId = new Cookies().get("companyId");
  // const getSubCategories = () => {
  //   const subItems = {};

  //   Object.keys(type).forEach((key) => {
  //     Array.isArray(RiskData[key]) &&  RiskData[key].forEach((item) => {
  //       subItems[item] = true;
  //     });
  //   });

  //   setSubCategory(subItems);
  // };

  const checkStatus = () => {
    const subItems = {};

    Object.keys(type).forEach((key) => {
      Array.isArray(RiskData[key]) && RiskData[key].forEach((item) => {
        subItems[item] = type[key];
      });
    });

    setSubCategoryStatus(subItems);
  };

  const filterData = () => {
    const allowedCategory = Object.keys(subCategory).filter(
      (key) => subCategory[key] && subCategoryStatus[key]
    );

    const filterData = cloneObject(initData)
      .filter(
        (item) =>
          item.type &&
          item.subCategory &&
          allowedCategory.includes(item.subCategory)
      )
      .map((item) => {
        const index = RiskType[item.type].indexOf(item.subCategory) + 1;
        item.subCategoryIndex = index;
        return item;
      });
    setData(filterData);
  };

  const cloneObject = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  const getData = async () => {
    let records: Risks[] = [];

    if (authUser.role === Roles.SUPER_ADMIN) {
      records = await DataStore.query(Risks, Predicates.ALL);
    } else {
      records = await DataStore.query(Risks, (c) => c.companiesID("eq", companyId));
    }

    setInitData(
      records.map((element) => {
        const item: Risks = { ...element };
        item.total = item.total || item.impact * item.probability;
        return {
          type: item.riskType,
          subCategory: item.subCategory,
          impact: item.impact,
          total: item.total,
          probability: item.probability,
        };
      })
    );
  };

  // useEffect(() => {
  //   getSubCategories();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    checkStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory, subCategoryStatus, initData]);

  return (
    <div className={classes.main}>
      <Grid container>
        <Grid item>
          <BubbleRadar data={data} />
        </Grid>
        <Grid item>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={classes.legend}>
              Risk Type
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={type.Technology}
                    onChange={() =>
                      setType({ ...type, Technology: !type.Technology })
                    }
                    name="technology"
                  />
                }
                label="Technology"
                style={{ color: "#fff" }}
              />
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={type.Process}
                    onChange={() =>
                      setType({ ...type, Process: !type.Process })
                    }
                    name="process"
                  />
                }
                label="Process"
                style={{ color: "#fff" }}
              />
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={type.Data}
                    onChange={() => setType({ ...type, Data: !type.Data })}
                    name="data"
                  />
                }
                label="Data"
                style={{ color: "#fff" }}
              />
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={type.Asset}
                    onChange={() => setType({ ...type, Asset: !type.Asset })}
                    name="asset"
                  />
                }
                label="Asset"
                style={{ color: "#fff" }}
              />
            </FormGroup>
          </FormControl>

          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={classes.legend}>
              Risk SubCategory
            </FormLabel>
            <FormGroup>
              {Object.keys(subCategory).map((key) => (
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={subCategory[key]}
                      onChange={() =>
                        setSubCategory({
                          ...subCategory,
                          [key]: !subCategory[key],
                        })
                      }
                      name={key}
                    />
                  }
                  key={`${key}-subcategory`}
                  label={key}
                  disabled={!subCategoryStatus[key]}
                  style={{ color: "#fff" }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
